<template>
  <web-header />
  <web-footer />
</template>

<script>
import footer from './views/footerVue.vue'
import header from './views/headerVue.vue'
export default {
  name: 'app',
  components: {
    'web-footer': footer,
    'web-header': header,
  }
}
</script>

<style>
#app {
  font-family: Times New Roman, Georgia, Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #282828 !important;
}

router-link {
  margin-top: 100px;
}

.nav-item:hover{
  text-shadow: #f4b859 0px 0px 35px ;
}

body {
  background-color: #282828;
  font-family: Times New Roman, Georgia, Serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 18px;
  overflow-x: hidden;
}


.imgnores {
  min-width: 240px;
}

.inline {
  display: inline;
}

nav a {
  color: white;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #f4b859;
  text-decoration: none;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

li {
  display: inline-block;
  margin: 5px 25px;
}

a {
  text-decoration: none;
}

a:hover {
  color: #f4b859;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.cardas:hover {
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(0, 255, 255, 0.7),
    1px 2px 4px 0px rgba(0, 255, 255, 0.7),
    2px 4px 8px 0px rgba(0, 255, 255, 0.7),
    2px 4px 16px 0px rgba(0, 255, 255, 0.7);
  z-index: 3;
  transition: all .4s;
}

.cardas {
  width: auto;
  height: auto;
  border: 0;
  transition: all .4s;
}

.ulDec {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


.ulDec {
  margin: auto;
}

body.modal-open { padding-right: 0 !important; 
}



button:hover{
  box-shadow: 0px 10px 50px 0px rgba(255, 177, 33, 0.4);
  color: black !important;
  background-color: #f4b859 !important;
  border: 1px solid #f4b859 !important;
}

.modal-close:hover {
  box-shadow: #282828 0px 0px 0px !important;
  color: black !important;
  background-color: #282828 !important;
  border: 0px solid #282828 !important;
} 

</style>
