<template>
    <header>

 <nav class="navbar navbar-expand-lg text-white sticky-top" style = "background-color: #282828; width: 100%;">
  <div class="text-white container-fluid">
    <ul class = "ulDec text-start"><li><router-link  class= "logo" to="/" style="font-size: 24pt; ">МАРТ</router-link></li></ul>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" 
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" style="background-color: #f4b859;">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="text-white collapse navbar-collapse" id="navbarNavAltMarkup">
      
        <ul class = "text-white navbar-nav me-auto mb-2 mb-lg-0"  >
          
          <li class="nav-item" ><router-link class="text-white active router-link-exact-active nav-link"  to="/">Главная</router-link></li>
          <li class="nav-item" ><router-link  class="text-white active router-link-exact-active  nav-link"   to="/about" >О компании</router-link></li>
          <li class="nav-item" ><router-link class="text-white active router-link-exact-active nav-link"   to="/service">Услуги</router-link></li> 
          <li class="nav-item" ><router-link class="text-white active router-link-exact-active nav-link"   to="/reviews">Отзывы</router-link></li>
          <li class="nav-item" ><router-link class="text-white active router-link-exact-active nav-link" to="/aboutservice">О сервисе</router-link></li>
          <li class="nav-item" ><a style = "color:#f4b859" class="active router-link-exact-active nav-link"  href="http://crm.mart78.com/">Вход в кабинет</a></li> 
        </ul>
    
    </div>
  </div>
</nav>  


<router-view/>

    </header>

    
</template>