<template>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog ">
    <div class="modal-content">
          <div class="modal-header" style = "border:none">
            <h5 class="modal-title me-2" id="modalLabel">Оставить заявку</h5>
            <button class="modal-close btn-empty" type="button" data-bs-dismiss="modal" aria-label="Close">
              <img src="../assets/close.png" style="width: 28px;">
            </button>
          </div>
          <div class="modal-body" >
            <form id="form3" class=" text-white send-data mx-auto" >
              <input type="hidden" name="form_type" value="form_block1">
              <input type="text" name="phone" class="input phone form__field mb-3" id="customer_phone" value="+7" size="25" placeholder="+7 (___) ___-__-__" >
              <input type="radio" style="display: none" name="mode" id="is_world" value="world" ><label for="is_world" style="display: none"></label>
              <input type="checkbox" id="phone_mask" style="display: none" >
              <input type="email" name="email" placeholder="E-mail" class="mb-3">
              <button type="submit" class="btn btn-lg mb-3"><span>Получить</span></button>
              <div>
                <input type="checkbox" name="privacy" id="privacy-5" >
                <label for="privacy-5">
                  <a target="_blank" href="https://disk.yandex.ru/i/J7te5069-SvsHw" class="privacy-link" >Согласие на обработку</a>
                  заявок с сайта для компаний-клиентов
                </label>
              </div>
            </form>
          </div>
        </div>
  </div>
</div>
  
    <footer class="text-white footer pt-5">
    <div class="container ">
      <div class="row">
        <div class="col-lg-1 d-flex flex-column align-items-lg-center">
          <span class="text-uppercase d-block" >МАРТ</span>
          <a href="#" class="d-flex flex-column align-items-center mt-4 mt-lg-auto mb-5 me-auto me-lg-0 link to-top">
            <img src="../assets/MainPage/decor/up.png" alt="Наверх" class="img-fluid">
            Наверх
          </a>
        </div>
        <div class="col-lg-6 offset-lg-1 mb-4 mb-lg-0">
          <h3 class="mb-4 text-uppercase text-justify" >
            Получите <em>+50 клиентов</em><br class="d-none d-xl-block">
            к первому пополнению!
          </h3>
          <p class="mb-0 text-justify" >
            Вам позвонят наши специалисты, мы проведем аудит вашей текущей ситуации и
            подготовим стратегию<br class="d-none d-xl-block">
            роста бизнеса на 25 - 300% за 2 месяца.
          </p>
        </div>
        <div class="col-lg-4">
          <button type="button" class="btn btn-primary btn-lg " data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Задать вопрос 
          </button>
          <div class="mb-5">
            <input type="checkbox" name="privacy" id="privacy-4">
            <label for="privacy-4">
              <a target="_blank" href="https://disk.yandex.ru/i/J7te5069-SvsHw" class="privacy-link" >Согласие на
                обработку</a>
              <span > заявок с сайта для компаний-клиентов</span>
            </label>
          </div>
          <p class="mini mb-0 text-justify">
            *Данные показатели являются ориентировочными и приведены лишь возможные
            значения на основе опыта работы компании. Фактические показатели могут
            существенно отличаться, поскольку зависят от личных, индивидуальных качеств
            менеджеров по продажам, от количества и качества посетителей сайта, а также
            качества и бюджета рекламной кампании.
          </p>
        </div>
      </div>
    </div>
    <div class="container px-3 pt-5 pb-3">
      <div class="row text-center">
        <div class="col-md-6 mb-3 mb-md-0">
          <a target="_blank" href="https://disk.yandex.ru/i/J7te5069-SvsHw">Соглашение об обработке данных</a>
        </div>
        <div class="col-md-6">
          <a target="_blank" href="https://disk.yandex.ru/i/YiY8xEM8Oaf3jQ" >Политика конфиденциальности</a>
        
        </div>
      </div>
    </div>
  </footer>


</template>

<style scoped>


.text-justify{
    text-align: justify;
}
.footer{
    background-color: #101010;
}

body {
  font-family: Times New Roman,Georgia,Serif;
  font-weight: 500;
  background-color: #282828  !important;
  font-size: 18px !important;
  overflow-x: hidden;
  padding-right: 0;  
}
.mini {
    font-size: 15px !important;
    font-weight: 400;
    color: #d0d0d0;
}

.mb-3 {
    margin-bottom: 1rem!important;
}
.privacy-link {
    text-decoration: underline;
}
a {
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
}

h1 em, h2 em, h3 em {
    font-style: normal;
    color: #f4b859;
}

.btn {
    padding: 14px;
    min-height: 48px;
    border-radius: 10px;
    background-color: #f4b859;
    border: 1px solid #f4b859;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: #282828;
    cursor: pointer;
}

input[type=text], input[type=email] {
    padding: 25px;
    width: 100%;
    max-width: 380px;
    height: 76px;
    border-radius: 10px;
    background-color: #acacac;
    text-align: center;
    color: #e4e4e4;
    font-size: 20px;
    line-height: 24px;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

h3{
    font-weight: 700;
    font-size: 35px;
}

.btn-lg {
    font-size: 20px;
    line-height: 24px;
    min-height: 77px;
    padding: 26px;
    width: 100%;
    max-width: 380px;
}

input[type=checkbox] + label::after {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background: transparent;
    border: solid 1px #f5b858;
}
*, ::after, ::before {
    box-sizing: border-box;
}
input[type=checkbox] {
    display: none;
}



input[type=checkbox]:checked + label::before {
    display: block;
}

input[type=checkbox] + label::before {
    content: "";
    position: absolute;
    display: none;
    left: 0;
    top: 5px;
    width: 21px;
    height: 15px;
    background-image: url(../assets/check.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

input[type=checkbox] + label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    font-weight: 400;
    font-size: 15px;
    color: #d0d0d0 ;
}

.modal-content {
    border: 1px solid #f4b859;
    background-color: #282828;
    color: #ffffff;
    box-shadow: 0px 30px 150px 0px rgba(255, 177, 33, 0.2);
}
.modal-dialog{
  border: 1px #f4b859 solid;
  border-radius: 5px;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #282828;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}

.btn-empty{
  background-color: #282828;
  border: none;
}


</style>